<template>
  <v-container fluid class="d-flex justify-center align-center fill-height">
    <v-card v-if="!dialogVisible" class="pa-6" width="500" max-width="600">
      <v-form @submit.prevent="handleSubmit">
        <v-text-field
          v-model="phone"
          label="Phone"
          type="tel"
          placeholder="Enter your phone number"
          outlined
          required
        ></v-text-field>

        <v-text-field
          v-model="password"
          label="Password"
          type="password"
          placeholder="Enter your password"
          outlined
          required
        ></v-text-field>
        <div class="d-flex justify-center">
          <v-btn
            type="submit"
            color="primary"
            class="w-full d-flex justify-center"
            :loading="isLoading"
          >
            Delete Account
          </v-btn>
        </div>
      </v-form>

      <v-alert
        v-if="errorMessage"
        type="error"
        class="mt-4 text-center"
        outlined
      >
        {{ errorMessage }}
      </v-alert>
    </v-card>

    <v-dialog v-model="dialogVisible" persistent max-width="700px">
      <v-card>
        <v-card-title class="text-h6 text-center"
          >Account Deletion Request</v-card-title
        >
        <v-card-text class="text-center mb-4 mt-4 subheading">
          {{ dialogMessage }}
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="back" class="mx-auto">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      phone: "",
      password: "",
      errorMessage: "",
      dialogVisible: false,
      dialogMessage: "",
      isLoading: false
    };
  },
  methods: {
    async handleSubmit() {
      if (!this.phone || !this.password) {
        this.errorMessage = "Please fill in both fields.";
        return;
      }
      this.errorMessage = "";
      this.isLoading = true;
      try {
        const response = await this.$apollo.mutate({
          mutation: require("../api/deleteAccount.graphql"),
          variables: {
            phone: this.phone,
            password: this.password
          }
        });
        if (
          response.data &&
          response.data.signIn &&
          response.data.signIn.user
        ) {
          const token = response.data.signIn.token;
          const customerprofile = await this.$apollo.query({
            query: require("../api/getCustomerProfile.graphql"),
            context: {
              headers: {
                Authorization: `Bearer ${token}`
              }
            }
          });
          if (customerprofile.data && customerprofile.data.getCustomerProfile) {
            const userId = customerprofile.data.getCustomerProfile._id;
            await this.deleteCustomer(userId, token);
          } else {
            this.isLoading = false;
            this.errorMessage =
              "Failed to get customer profile. Please try again.";
          }
        } else {
          this.isLoading = false;
          this.errorMessage = "Failed to sign in. Incorrect phone or password.";
        }
      } catch (error) {
        console.error("Error during account deletion:", error);
        this.isLoading = false;
        this.errorMessage = "An error occurred. Please try again.";
      }
    },
    async deleteCustomer(userId, token) {
      try {
        const response = await this.$apollo.mutate({
          mutation: require("../api/deleteCustomer.graphql"),
          variables: {
            id: userId
          },
          context: {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        });
        if (response.data.deleteCustomer) {
          this.isLoading = false;
          this.dialogMessage = "Account deleted ";
          this.dialogVisible = true;
          this.phone = "";
          this.password = "";
        } else {
          this.errorMessage = "Failed to delete account. Please try again.";
        }
      } catch (error) {
        this.isLoading = false;
        console.error("Error during account deletion:", error);
        this.errorMessage =
          "An error occurred while deleting your account. Please try again.";
      }
    },

    back() {
      this.$router.push("/home");
    }
  }
};
</script>

<style scoped></style>
